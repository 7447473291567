import "./bootstrap";
import "../css/app.css";

import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/inertia-react";
import { InertiaProgress } from "@inertiajs/progress";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import chatReducer from "./ReduxToolkit/chatSlice";
//...
import * as Sentry from "@sentry/react";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";
// Configure Redux store
const rootReducer = combineReducers({
    chat: chatReducer,
    // Add other reducers here if needed
});
const store = configureStore({
    reducer: rootReducer,
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.jsx`,
            import.meta.glob("./Pages/**/*.jsx")
        ),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(
            <Provider store={store}>
                <div>
                    <ToastContainer />
                    <App {...props} />
                </div>
            </Provider>
        );
    },
});

InertiaProgress.init({ color: "#892887" });

Echo.private(`messenger.1.2`).listen("MessageSent", (e) => {
    console.log(e);
    console.log(e.message);
});

Sentry.init({
    dsn: "https://924a1e95d924517946f79769491dfd70@o4507206579388416.ingest.de.sentry.io/4507206583386192",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

// Echo.join(`group_chat.1`)
//     .here((users) => {
//         console.log(users);
//     })
//     .joining((user) => {
//         console.log(user.name);
//     })
//     .leaving((user) => {
//         console.log(user.name);
//     })
//     .listen('GroupChatMessage', (e) => {
//         console.log(e);
//     })
//     .error((error) => {
//         console.error(error);
//     });
