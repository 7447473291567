// chatSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isStartChatPopupOpen: false,
  confirmUserPopupDetails: [], // Initialize with null or an initial object
  activeChat: null, // Initialize with null or an initial object
  activeChatTimer: null, // Initialize with null or an initial object
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setIsStartChatPopupOpen: (state, action) => {
        state.isStartChatPopupOpen = action.payload;
    },
    setConfirmUserPopupDetails: (state, action) => {
        state.confirmUserPopupDetails = action.payload;
    },
    setActiveChat: (state, action) => {
        state.activeChat = action.payload;
    },
    setActiveChatTimer: (state, action) => {
        state.activeChatTimer = action.payload;
    },
  },
});

export const { setIsStartChatPopupOpen, setConfirmUserPopupDetails, setActiveChat, setActiveChatTimer } = chatSlice.actions;
export const getIsStartChatPopupOpen = (state) => state.chat.isStartChatPopupOpen;
export const getConfirmUserPopupDetails = (state) => state.chat.confirmUserPopupDetails;
export const getActiveChat = (state) => state.chat.activeChat;
export const getActiveChatTimer = (state) => state.chat.activeChatTimer;
export default chatSlice.reducer;
